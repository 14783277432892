<template>
  <b-nav :vertical="isVerticalNavbar" pills>
    <b-nav-item
      v-for="(route, idx) in routes"
      :key="idx"
      :to="route.path"
      v-show="canBeShown(route)"
      exact-active-class="bg-secondary text-light rounded-0"
      :link-classes="linkClass"
      >{{ route.name }}
    </b-nav-item>
  </b-nav>
</template>

<script>
import routes from '../router/routes'
import { mapState } from 'vuex'

export default {
  props: {
    isVerticalNavbar: {
      type: Boolean,
      default: true,
      required: true,
    },
    linkClass: { type: String, default: 'text-info' },
  },
  data() {
    return {
      routes: routes,
    }
  },
  computed: {
    ...mapState(['store_auth']),
  },
  methods: {
    canBeShown(route) {
      const meta = route.meta || null
      const path = route.path || null
      if (!meta || !path) return false // Une erreur dans le router Vue ?

      const isLogged = this.store_auth.isLogged
      const isAdmin = this.store_auth.isAdmin
      const requireAuth = meta.requires.auth
      const requireAnonymous = meta.requires.anonymous
      const requireAdmin = meta.requires.admin
      const requireNav = meta.requires.nav

      // Route en cours d'utilisation
      if (this.$route.path === path) return true
      // Requiert d'etre connecté
      else if (requireAuth && !isLogged) return false
      // Tentative de connexion à une page de login
      else if (isLogged && requireAnonymous) return false
      // Requiert d'etre admin et connecté
      else if (!isAdmin && requireAdmin) return false
      // Doit être affichée dans la barre de navigation
      else if (!requireNav) return false
      // Conditions respectées
      else return true
    },
  },
}
</script>
